import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mobile from "./utils/mobile";
import util from "./utils/util"
import Vant from 'vant';
import 'vant/lib/index.css';
//引入请求方法
import request from './utils/request';
// 引入api
import api from './api/api.js'
Vue.prototype.$api = api
Vue.prototype.$request = request
Vue.prototype.$utils = util
Vue.config.productionTip = false
Vue.use(mobile)
Vue.use(Vant);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
