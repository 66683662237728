/*
 * @Author: Mr.Cheng
 * @Date: 2023-08-28 11:24:20
 * @LastEditTime: 2023-09-11 09:58:57
 * @FilePath: \cashier\src\api\api.js
 */
//接口名称
var api = {
    payInfo: 'index/index/payment_page',//支付信息
    openid: '/api/user/openid',//获取openid
    paymentProduct: '/api/pay/paymentProduct',//支付
}
export default api