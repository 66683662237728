<!--
 * @Author: Mr.Cheng
 * @Date: 2023-08-28 09:27:10
 * @LastEditTime: 2023-09-12 17:03:12
 * @FilePath: \cashier\src\App.vue
-->
<!--
 * @Author: Mr.Cheng
 * @Date: 2023-08-28 09:27:10
 * @LastEditTime: 2023-08-28 09:50:02
 * @FilePath: \收银台\cashier\src\App.vue
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
// 重置alert
window.alert = function (name) {
  const iframe = document.createElement("IFRAME");
  iframe.style.display = "none";
  iframe.setAttribute("src", "data:text/plain,");
  document.documentElement.appendChild(iframe);
  window.frames[0].window.alert(name);
  iframe.parentNode.removeChild(iframe);
};

// 重置confirm
window.confirm = function (message) {
  const iframe = document.createElement("IFRAME");
  iframe.style.display = "none";
  iframe.setAttribute("src", "data:text/plain,");
  document.documentElement.appendChild(iframe);
  const alertFrame = window.frames[0];
  const result = alertFrame.window.confirm(message);
  iframe.parentNode.removeChild(iframe);
  return result;
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
</style>
