<template>
  <div class="home">
    <div class="title">收银台</div>
    <div class="content">
      <!-- v-if="payMethod != 3" -->
      <div v-if="pay_price" class="money-info">
        <div class="num">{{ pay_price }}</div>
        <!-- <div class="time">支付剩余时间：{{ timeText }}</div>
        <div class="tips">下单后请在15分钟内付款，超时未付款的订单将被关闭</div> -->
      </div>

      <!-- 微信支付 -->
      <div v-if="payMethod == 1" class="payment-method">
        <div class="text">选择支付方式</div>
        <div class="pay">
          <div style="display: flex; align-items: center">
            <img class="pay-img" src="../../src/assets/wx.png" alt="" />
            <div class="pay-text">微信支付</div>
          </div>
          <img class="active" src="../../src/assets/active.png" alt="" />
        </div>
      </div>
      <!-- 支付宝支付 -->

      <div v-if="payMethod == 2" class="payment-method">
        <div class="text">选择支付方式</div>
        <div class="pay">
          <div style="display: flex; align-items: center">
            <img class="pay-img" src="../../src/assets/zfb.png" alt="" />
            <div class="pay-text">支付宝支付</div>
          </div>
          <img class="active" src="../../src/assets/active.png" alt="" />
        </div>
      </div>
      <!-- 都不是 -->
      <div v-if="payMethod == 3" class="user-tips">
        请使用微信或支付宝扫一扫打开
      </div>
      <div @click="pay()" class="pay-btn">确认支付</div>
    </div>
  </div>
</template>

<script>
import "../utils/alipayjsapi_3.1.1.min";
export default {
  data() {
    return {
      payMethod: 1, //1微信，2支付宝,3都不是
      pay_endTime: 564,
      timer: null,
      timeText: "",
      code: "",
      order_sn: "", //订单编号
      pay_price: "", //支付金额
      alipay_sdk: "", //支付订单信息
    };
  },
  methods: {
    //支付 支付方式:0=未选择,1=微信,2=支付宝
    pay() {
      if (this.payMethod == 1) {
        if (sessionStorage.getItem("openid")) {
          this.wxpay();
        }
      } else if (this.payMethod == 2) {
        this.zfbpay();
      }
    },
    //获取openid
    getOpenid() {
      this.$request
        .request(this.$api.openid, { code: this.code })
        .then((res) => {
          if (res.code == 1) {
            sessionStorage.setItem("openid", res.data.openid);
          }
        });
    },
    //获取重定向中的code
    getCodeUrl() {
      //公众号的唯一标识
      const APPID = "wx7f3f4eb039a82c81";
      //授权后重定向的回调链接地址
      const REDIRECT_URI = window.location.href.split("?")[0]; //encodeURIComponent("http://xcpay.59156.cn/");
      const SCOPE = "snsapi_base"; //静默获取无需确定snsapi_base 需用户手动确定snsapi_userinfo
      var url =
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=` +
        APPID +
        `&redirect_uri=` +
        REDIRECT_URI +
        `&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      location.href = url;
      return;
    },
    onBridgeReady(res) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", res, (wxResponse) => {
        console.log(wxResponse);
        if (wxResponse.err_msg == "get_brand_wcpay_request:ok") {
          WeixinJSBridge.call("closeWindow");
        }
        if (wxResponse.err_msg == "get_brand_wcpay_request:fail") {
          _this.$toast("支付失败");
        }
        if (wxResponse.err_msg == "get_brand_wcpay_request:cancel") {
          _this.$toast("支付取消");
        }
      });
    },
    isWechat() {
      return new Promise((resolve, reject) => {
        var userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
          resolve(1);
        } else if (userAgent.match(/Alipay/i) == "alipay") {
          resolve(2);
        } else {
          resolve(3);
        }
      });
    },
    // 待付款倒计时
    count_down() {
      let that = this;
      var { timer, pay_endTime } = this;
      if (pay_endTime > 0) {
        timer = setInterval(function () {
          if (pay_endTime > 0) {
            var str = that.$utils.formatSeconds(pay_endTime);
            that.timeText = str;
            pay_endTime--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      }
    },
    //微信获取支付
    wxpay() {
      let params = {
        order_sn: this.order_sn,
        pay_type: this.payMethod,
        type: 1,
        openid: sessionStorage.getItem("openid"),
      };
      this.$request.request(this.$api.paymentProduct, params).then((res) => {
        if (res.code == 1) {
          let { jsApiparamter } = res.data;
          this.jsApiparamter = jsApiparamter;
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener(
                "WeixinJSBridgeReady",
                onBridgeReady,
                false
              );
            } else if (document.attachEvent) {
              document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
              document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
            }
          } else {
            this.onBridgeReady(jsApiparamter);
          }
        } else {
          this.$dialog
            .alert({
              title: "提示",
              message: res.msg,
            })
            .then(() => {
              if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                  document.addEventListener(
                    "WeixinJSBridgeReady",
                    onBridgeReady,
                    false
                  );
                } else if (document.attachEvent) {
                  document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                  document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
                }
              } else {
                WeixinJSBridge.call("closeWindow");
              }
            });
        }
      });
    },
    async zfbInfo() {
      let _this = this;
      this.order_sn = await this.$utils.getParam("order_sn");
      this.pay_price = await this.$utils.getParam("pay_price");
      if (this.order_sn) {
        sessionStorage.setItem("order_sn", this.order_sn);
      }
      if (this.pay_price) {
        sessionStorage.setItem("pay_price", this.pay_price);
      }

      if (!this.order_sn) {
        this.order_sn = sessionStorage.getItem("order_sn");
      }
      if (!this.pay_price) {
        this.pay_price = sessionStorage.getItem("pay_price");
      }
    },
    // 支付宝支付
    zfbpay() {
      let _this = this;
      let params = {
        order_sn: this.order_sn,
        pay_type: this.payMethod,
      };
      this.$request.request(this.$api.paymentProduct, params).then((res) => {
        if (res.code == 1) {
          _this.alipay_sdk = res.data;
          window.AlipayJSBridge.call(
            "tradePay",
            {
              orderStr: _this.alipay_sdk, //  支付订单信息
            },
            function (result) {
              // 支付成功回调判断
              if (result.resultCode == 9000) {
                //这个可以关闭安卓系统的手机
                document.addEventListener(
                  "AlipayJSBridgeReady",
                  function () {
                    AlipayJSBridge.call("closeWindow");
                  },
                  false
                );
                //这个可以关闭ios系统的手机
                AlipayJSBridge.call("closeWebview"); //支付宝
                // uni.navigateTo({
                //   url: "/pages/pay_order/succee?price=" + that.orderInfo.total,
                // });
              } else {
                console.log("支付失败");
              }
            }
          );
        } else {
          this.$dialog
            .alert({
              title: "提示",
              message: res.msg,
            })
            .then(() => {
              setTimeout(() => {
                //这个可以关闭安卓系统的手机
                document.addEventListener(
                  "AlipayJSBridgeReady",
                  function () {
                    AlipayJSBridge.call("closeWindow");
                  },
                  false
                );
                //这个可以关闭ios系统的手机
                AlipayJSBridge.call("closeWebview"); //支付宝
              }, 500);
            });
        }
      });
    },
  },
  async mounted() {
    this.payMethod = await this.isWechat();

    // 微信
    if (this.payMethod == 1) {
      this.code = await this.$utils.getParam("code");

      this.order_sn = await this.$utils.getParam("order_sn");
      this.pay_price = await this.$utils.getParam("pay_price");
      if (this.order_sn) {
        sessionStorage.setItem("order_sn", this.order_sn);
      }
      if (this.pay_price) {
        sessionStorage.setItem("pay_price", this.pay_price);
      }

      if (!this.order_sn) {
        this.order_sn = sessionStorage.getItem("order_sn");
      }
      if (!this.pay_price) {
        this.pay_price = sessionStorage.getItem("pay_price");
      }
      if (!sessionStorage.getItem("openid")) {
        if (!this.code) {
          this.getCodeUrl();
        } else {
          this.getOpenid();
        }
      }
    }
    // 支付宝
    else if (this.payMethod == 2) {
      this.zfbInfo();
    } else {
      this.$dialog
        .alert({
          title: "提示",
          message: "请用微信或支付宝扫码支付",
        })
        .then(() => {
          // on close
        });
    }

    // this.count_down();
  },
};
</script>
<style lang="less" scoped>
.title {
  width: 100vw;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}

.content {
  width: 100vw;
  height: calc(100vh - 3rem);
  background: #f2f3f5;
  display: flex;
  padding: 0 1rem;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  .money-info {
    margin-top: 2.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    .num {
      font-size: 2rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
      text-align: center;
    }
    .num::before {
      content: "￥";
      font-size: 1.4rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
    }
    .time {
      margin-top: 1.2rem;
      // padding: 0.5rem 1.8rem;
      height: 2rem;
      width: 17rem;
      line-height: 2rem;
      text-align: center;
      // box-sizing: border-box;
      background: #fffeff;
      border-radius: 50px;
      font-size: 0.9rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4e4e4e;
    }
    .tips {
      font-size: 0.9rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      margin-top: 1.2rem;
    }
  }
  .payment-method {
    width: 100%;
    margin-top: 1.9rem;
    padding: 1.9rem 0.9rem;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 0.9rem;
    .text {
      font-size: 1.1rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #353535;
    }
    .pay {
      display: flex;
      margin-top: 2.2rem;
      justify-content: space-between;
      align-items: center;
      .pay-img {
        margin-right: 0.7rem;
        width: 2.4rem;
        height: 2.4rem;
      }
      .pay-text {
        font-size: 1.1rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .active {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }
  .user-tips {
    font-size: 1.2rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    margin-top: 2.2rem;
  }
  .pay-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 93vw;
    height: 3rem;
    background: #e41919;
    border-radius: 0.2rem;
    font-size: 1.2rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    position: fixed;
    bottom: 1rem;
  }
}
</style>