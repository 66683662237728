/*
 * @Author: Mr.Cheng
 * @Date: 2023-08-28 09:27:10
 * @LastEditTime: 2023-08-28 14:39:26
 * @FilePath: \收银台\cashier\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
