/*
 * @Author: Mr.Cheng
 * @Date: 2023-08-28 10:44:15
 * @LastEditTime: 2023-09-08 17:25:24
 * @FilePath: \cashier\src\utils\util.js
 */
//将秒转为时分秒
function formatSeconds(value) {
    let result = parseInt(value)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

    let res = '';
    let display = '';
    if (h !== '00') {
        res += `${h}时`;
    } else {
        res += `${h}时`;
    }
    if (m !== '00') {
        res += `${m}分`;
    } else {
        res += `${m}分`
    }
    res += `${s}秒`;
    return res;
}
//获取链接上参数
function getParam(variable) {
    var query = window.location.search.substring(1);
    console.log("param：" + query);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return (false);
}
export default { formatSeconds, getParam }