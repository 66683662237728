/*
 * @Author: Mr.Cheng
 * @Date: 2023-08-28 11:21:03
 * @LastEditTime: 2023-09-11 09:29:57
 * @FilePath: \cashier\src\utils\request.js
 */
/*
 * @Author: Mr.Cheng
 * @Date: 2023-06-26 11:17:23
 * @LastEditTime: 2023-08-28 13:35:36
 * @FilePath: \收银台\cashier\src\utils\request.js
 */

import axios from 'axios' // 引入axios
import qs from 'qs' // 引入qs




const baseURL = 'https://xcservice.59156.cn/'
axios.defaults.baseURL = baseURL;


let status = true//是否提示未登录


//添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config;
})

// 拦截响应response，进行错误处理
axios.interceptors.response.use(function (response) {
  // 成功处理
  return response;
}, function (error) {
  // 失败处理
  console.log(error);
  error.code = error.response.status
  switch (error.response.status) {
    case 400:
      error.message = '错误请求'
      break;
    case 401:
      error.message = '未授权，请重新登录'
      break;
    case 403:
      error.message = '拒绝访问'
      break;
    case 404:
      error.message = '请求错误,未找到该资源'
      break;
    case 405:
      error.message = '请求方法未允许'
      break;
    case 408:
      error.message = '请求超时'
      break;
    case 500:
      error.message = '服务器端出错'
      break;
    case 501:
      error.message = '网络未实现'
      break;
    case 502:
      error.message = '网络错误'
      break;
    case 503:
      error.message = '服务不可用'
      break;
    case 504:
      error.message = '网络超时'
      break;
    case 505:
      error.message = 'http版本不支持该请求'
      break;
    default:
      error.message = `连接错误${error.response.status}`
  }
  return Promise.reject(error);
});

/**
 * 发送请求
 * @param {*} Uri 请求 api
 * @param {*} params 请求参数
 * @param {*} method 请求类型 get / post
 * @param {*} headers Object 请求头设置
 * @param {*} responseType string 请求相应类型，设置
 * @returns 
 */
async function request(Uri, params, method, headers, responseType) {
  // 这里主要是post方法的封装，get方法同理

  var options = {
    url: Uri,
  };
  options.method = method || 'post'
  if (options.method == 'get' || options.method == 'GET') {
    options.params = params;
  }
  if (options.method == 'post' || options.method == 'POST') {
    options.data = params;
  }
  if (options.config === 'formData') {
    options.headers = { 'Content-Type': 'multipart/form-data' }
  }
  if (!!headers) {
    options.headers = headers
  }
  if (!!responseType) {
    options.responseType = responseType
  }
  /* else {
    options.data = qs.stringify(options.data)
  }*/
  return new Promise((resolve, reject) => {
    axios(options).then(res => {
      console.log(res);
      //  这里主要根据后来返回的数据作判断，请根据实际情况
      if (res.status === 200) {
        if (res.data.code == 1) {
          console.log(res, 222);
          resolve(res.data)
        } else {
          console.log(res, 333);

          resolve(res.data)
        }
      } else {
        console.log(res, 444);

        reject(res.data)
      }
    }).catch(error => {
      console.log(error, 555);

    })
  })
}

export default { request, baseURL }

//安装axios
//npm i axios

//安装qs
// npm i qs

//main.js
// import Request from '@/utils/request' // 引入封装的文件
// const { request } = Request
// Vue.prototype.$http = request // 挂载到全局上

//使用方法
/*

mounted () {
  that.$http('/app/index/index', {}).then(res =>{
    console.log(res);

  });
}

*/